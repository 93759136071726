import React from 'react'
import style from './terms.module.scss'
import { Container, Grid } from '@mui/material'

export const Terms_conditions = () => {
    return (
        <div className={style.wrapper_father}>
            <Container className={style.wrapper}>
                <h1>PayZone Terms of Use</h1>
                <p>
                    Please read these Terms of Use ("<b>Terms</b>") carefully before using our Services. High Morale Developments Limited, a company incorporated in the Hong Kong Special Administration Region of the People’s Republic of China ("<b>we</b>", "<b>our</b>" and "<b>us</b>"), provides e-commerce services ("<b>Services</b>") using our online Midasbuy platform (the "<b>Platform</b>") to facilitate the sale and purchase of (i) credits (“<b>Game Credits</b>”) which may be used to purchase virtual goods and items (such as weapons) (“<b>Gaming Items</b>”), or such virtual goods directly, for use within games ("<b>Game(s)</b>") operated by our affiliates and other third parties (the "<b>Operators</b>"); or (ii) subscriptions for Game Credits or Gaming Items or other digital content operated or otherwise provided by the Operators (together, the "<b>Items</b>"). By taking any step to register for and/or use all or any portion of the Services, you accept and agree to be bound by all the terms and conditions of these Terms. If you do not agree to any terms and conditions of these Terms, you must not use the Services. <br />
                    <b>If you live in the European Union, United Kingdom, Switzerland or Norway, please note there are some different terms which apply to you which can be found in Clause 21.</b> <br /> <br />
                    Please also read the Privacy Policy and any other policies we communicate to you from time to time, which, together with these Terms, are the entire agreement between you and us regarding your use of the Services and the terms of which are incorporated by reference into these Terms. <br /> <br />
                    If you are under the age of 18 (or the relevant age in your jurisdiction where you are considered a minor), your parent or guardian must agree to these Terms (both for themselves and on your behalf) before you can use the Platform. <br /> <br />
                    We may translate these Terms into multiple languages. If there is any difference between the English version and any other language version of these Terms, the English version will prevail and apply (to the extent permitted by applicable laws and regulations). <br /> <br />
                    Our registered office is at: 29/F, Three Pacific Place, No.1 Queen’s Road East, Wan Chai, Hong Kong. Please contact us at help@Payzone.com if you have any complaints or queries about the Services. <br /> <br />
                </p>
                <p className={style.wrapper_Uppercase}>IMPORTANT NOTE ABOUT ITEMS AND OPERATOR'S TERMS</p> <br />
                <p>
                    <b>
                        1.1 <span className={style.wrapper_tabs}>
                            It is very important that you read and understand the Operator's terms and conditions governing: (i) the sale of the Items by the Operator as seller to you as buyer; (ii) your rights in the Items; and (iii) your use of the relevant Game or Items (together, the "Operator's Terms") before purchasing any Items using the Services. The Operator's Terms set out the terms on which you purchase the Items and your rights and obligations with respect to the use of such Items, including the value or worth of such Items and the terms and conditions applicable to their use, transfer, redemption or cancellation.
                        </span>
                    </b> <br /><br />
                </p>
                <p>
                    <b>
                        1.2 <span className={style.wrapper_tabs}>
                            We are not responsible for and do not endorse any particular Game or Item.  We do not grant you any rights to use the Items under these Terms. Any such rights shall be between you and the Operator under the Operator's Terms.
                        </span>
                    </b> <br /><br />
                </p>
                <p>
                    <b>
                        1.3 <span className={style.wrapper_tabs}>
                            We are not a party to the Operator's Terms, which are between you and the Operator.
                        </span>
                    </b> <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>YOUR INFORMATION</p> <br />
                <p>Your privacy is important to us and we will treat all personal data that you provide to us in accordance with the Privacy Policy, as updated from time to time.</p> <br /><br />
                <p className={style.wrapper_Uppercase}>LICENCE AND ACCESS</p> <br />
                <p>
                    3.1 <span className={style.wrapper_tabs}>
                        Provided that you comply with these Terms and pay us any applicable fees, we grant you a limited, personal, non-exclusive, non-transferable, non-sublicensable, royalty-free and revocable licence to access and make personal and non-commercial use of the Services and any software we make available as part of the Services. This licence is limited to and does not include or extend to any resale or commercial use of the Services or their content; any collection and use of any product listings, descriptions or prices; any derivative use of the Services or their contents; any downloading or copying of account information for the benefit of any person; or any use of the Services in conjunction with any data mining tools, robots, scrapers or similar data gathering and extraction tools.
                    </span>
                    <br /><br />
                </p>
                <p>
                    3.2 <span className={style.wrapper_tabs}>
                        All rights not expressly granted to you in these Terms are reserved and retained by us, our affiliates or our licensors, suppliers, publishers, rights holders or other content providers as applicable. The Services, or any part of the Services, may not be reproduced, duplicated, copied, sold, resold, visited or otherwise exploited for any commercial purpose. Your use of any intellectual property rights belonging to us, or our affiliates, licensors, suppliers, publishers, rights holders or content providers is subject to these Terms.
                    </span>
                    <br /><br />
                </p>
                <p>
                    3.3 <span className={style.wrapper_tabs}>
                        Any goodwill accruing out of the use of our and our affiliates' trademarks, trade and business names and service marks under these Terms will vest in us and our affiliates, as the case may be.
                    </span>
                    <br /><br />
                </p>
                <p>
                    3.4 <span className={style.wrapper_tabs}>
                        We reserve the right to refuse to provide the Services to anyone for any reason, in our sole and absolute discretion without being obliged to provide you with any reason or notification.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>YOUR OBLIGATIONS AND USE OF THE SERVICES</p> <br />
                <p>
                    4.1 <span className={style.wrapper_tabs}>
                        You may use the Services in accordance with these Terms to search for product information relating to Items, and pre-order and purchase Items as may be provided for on the Platform from time to time.
                    </span>
                    <br /><br />
                </p>
                <p>
                    4.2 <span className={style.wrapper_tabs}>
                        In order to use the Services you must comply with the requirements as set out in these Terms. If you do not comply with these requirements at any point you must cease using the Services immediately and to the extent permitted under applicable law, you shall be liable for any legal consequences, including costs, losses or damages which we may suffer or incur as a result.
                    </span>
                    <br /><br />
                </p>
                <p>
                    4.3 <span className={style.wrapper_tabs}>
                        You shall provide all necessary information and supporting documents (including but not limited to the proof of your billing address and mobile phone number) as reasonably requested by us from time to time for the purpose of your use of the Services, taxation and/or compliance with applicable laws in any jurisdiction. We reserve the right to claim against you for any legal consequences, including costs, losses or damages which we may suffer or incur should any of the information or documents provided by you is false, inaccurate, incomplete or misleading.
                    </span>
                    <br /><br />
                </p>
                <p>
                    4.4 <span className={style.wrapper_tabs}>
                        You must not use the Services: (a) in any way that causes, or is likely to cause, the Services, or any access to the Services, to be interrupted, damaged or impaired in any way; or (b) for fraudulent purposes, or in connection with a criminal offence or other unlawful activity.
                    </span>
                    <br /><br />
                </p>
                <p>
                    4.5 <span className={style.wrapper_tabs}>
                        You must not use fraudulent means to purchase Items or make use of any payment methods to purchase Items for fraudulent purposes or otherwise in connection with a criminal offence or other unlawful activity. If such activity is detected by us, we may refuse any purchase or initiate a refund to you relating to such Items. In the event a refund is initiated by us, the relevant Operator shall disable your access to the relevant Items and/or retrieve the relevant Items from you. We reserve the right to claim against you for any legal consequences, including costs, losses or damages which we may suffer or incur as a result.
                    </span>
                    <br /><br />
                </p>
                <p>
                    4.6 <span className={style.wrapper_tabs}>
                        In addition to the restrictions set out in these Terms, you must not use the Services: <br /><br />

                        <span className={style.wrapper_tabs_inside}>(a) <span className={style.wrapper_tabs}>to cause or potentially cause any unreasonable or disproportionate burden on the Services;</span></span> <br />
                        <span className={style.wrapper_tabs_inside}>(b) <span className={style.wrapper_tabs}>to interfere or attempt to interfere with the Services or our ability to provide the Services; or</span></span> <br />
                        <p className={style.wrapper_tabs_inside}>(c) <span className={style.wrapper_tabs}>to manipulate any transactions carried out via the Services in any manner that is in breach of these Terms, any Operator's Terms or in any manner that is contrary to applicable law.</span></p> <br />
                    </span>
                    <br /><br />
                </p>
                <p>
                    4.7 <span className={style.wrapper_tabs}>
                        You hereby acknowledge and agree that Operators, and not us or any other of our affiliates, are responsible for accurately listing their Items and you are responsible for reading the description of the Items listed by Operators and making your own judgments before making a purchase. All sales are binding and you agree to perform all your obligations in relation to such sale.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>WARRANTY</p> <br />
                <p>
                    Our Services are provided to you on an "as is", "where-is" basis based on existing technology, however, we warrant to you that we will provide the Services using reasonable skill and care. Subject to applicable law, we make no other representation, condition or warranty of any kind, express or implied, and you hereby waive all such representations, conditions and warranties, including, without limitation: (a) that the Services or the Items will meet your requirements, will always be available, accessible, uninterrupted, timely, secure or operate without error; (b) that Items or information or content relating to the Items included on or available through the Services will be as represented by Operators, available for sale at the time of listing, lawful to sell, or that Operators will perform as promised; (c) that Items will represent fair value, retain their value or otherwise meet your expectations as to their worth, exchange value or utility; and (d) without limiting the generality of the foregoing: (i) any implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement; (ii) any implied warranty arising from course of dealing or usage of trade; (iii) any implied obligation, liability, right, claim or remedy under contract; and (iv) any obligation, liability, right, claim or remedy in tort, whether or not arising from our negligence, in equity, or otherwise. To the fullest extent permissible under applicable law, we disclaim any and all such representations, conditions and warranties.
                </p>
                <p className={style.wrapper_Uppercase}>OUR ROLE</p> <br />
                <p>
                    6.1 <span className={style.wrapper_tabs}>
                        We will assist in facilitating transactions that are carried out on the Platform, but we are neither the buyer nor the seller of Items that are the subject of a transaction carried out through the Services. Any agreement for the sale of any Items is solely between you as the buyer, and the Operator as the seller. We are not a party to that agreement and we do not have any responsibility in connection with it nor are we an agent for the Operator.
                    </span>
                    <br /><br />
                </p>
                <p>
                    6.2 <span className={style.wrapper_tabs}>
                        Please note that we do not directly provide any credit or debit, stored value and/or payment processing services for sales or purchases conducted on the Platform. Such facilities and services are performed by and are the responsibility of the issuer or operator of your relevant credit or debit card, stored value facility, payment service or other payment instrument (each, a "<b>Payment Instrument</b>" and such issuers and operators, the "<b>Payment Provider</b>") under separate terms and conditions which you have agreed with the Payment Provider.
                    </span>
                    <br /><br />
                </p>
                <p>
                    6.3 <span className={style.wrapper_tabs}>
                        You acknowledge and agree that: <br /><br />

                        <span className={style.wrapper_tabs_inside}>(a) <span className={style.wrapper_tabs}>we are granted by Operators authority to collect and receive payments as for transactions that are carried out on the Platform between you as the buyer and Operator as the seller on behalf of the Operator from you; and</span></span> <br />
                        <span className={style.wrapper_tabs_inside}>(b) <span className={style.wrapper_tabs}> you are released from the payment obligation to the Operator at the time the payment from you to us is completed as for such a particular transaction.</span></span> <br />
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>OUR RIGHTS AND OBLIGATIONS</p> <br />
                <p>
                    7.1 <span className={style.wrapper_tabs}>
                        We retain the right, in our sole discretion, to suspend or terminate the Services, prevent or restrict access to Services, take any other action to restrict your access to or availability of the Services or to suspend or terminate your access to the Services if we consider you may have breached these Terms or otherwise made inappropriate use of the Services.
                    </span>
                    <br /><br />
                </p>
                <p>
                    7.2 <span className={style.wrapper_tabs}>
                        You acknowledge and agree that we are entitled to offer cooperation and provide information, including information about or provided by you, to any administrative or judicial authority in any jurisdiction.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>ADVERTISEMENT AND PROMOTIONS</p> <br />
                <p>
                    8.1 <span className={style.wrapper_tabs}>
                        We may provide you with options to close or minimise advertisements on the Platform, but you must not in any way screen or filter the advertisements in any manner not expressly permitted by us in writing.  We may also, from time to time, carry out marketing and promotional activities in relation to the Platform, such as "red packet" giveaways, or top-up gifts.
                    </span>
                    <br /><br />
                </p>
                <p>
                    8.2 <span className={style.wrapper_tabs}>
                        Save as required by applicable laws, we shall have no liability for any loss or damage incurred or suffered by you arising from transactions you enter into in reliance upon such advertisements or promotional and marketing information.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>PRICE, DELIVERY OF ITEMS AND SUBSCRIPTIONS</p> <br />
                <p>
                    9.1 <span className={style.wrapper_tabs}>
                        Price <br />
                        The price you will pay for your purchase of Items is determined by the Operator and denominated in the currency at the election of the Operator (the "Purchase Currency") from time to time. Please note that the price for Items as may be provided for on the Platform from time to time may vary to reflect the Purchase Currency. Please be aware of and carefully consider any recurring charges that may arise from the purchase of Items. You may also be informed of an approximate value in your local currency of the actual Purchase Currency price for your purchase. The approximate local currency price uses historical, not real-time, currency exchange rates and is only an approximation of the value of the Purchase Currency price for your purchase.
                        If the Payment Instrument you use to pay for your purchase is not denominated in the Purchase Currency, the amount you pay will be your local currency equivalent (as determined by your Payment Provider) of the actual Purchase Currency price. Your Payment Provider may also charge you other fees (including but not limited to currency conversion and/or transaction fees) for you making a purchase in the Purchase Currency rather than your local currency (such as where you use a credit card from an overseas jurisdiction/region to complete your purchase). Please contact your Payment Provider for more information about these additional fees. You are solely responsible for any applicable taxes, charges or fees imposed by your Payment Provider in connection with your use of the Services. We and our affiliates shall not be responsible or liable for any exchange rate used by your Payment Provider. Applicable taxes shall be ascertained based on the information and declaration provided by you.
                    </span>
                    <br /><br />
                </p>
                <p>
                    9.2 <span className={style.wrapper_tabs}>
                        Delivery of Items <br />
                        The Operator shall be responsible for the delivery of Items transacted through the Services, and we will not be liable or responsible to you in any way for the delivery of such Items. <br /><br />
                        If you request a refund for any Items, or if the Operator cannot charge your payment method for any reason (such as insufficient funds or expiration of your payment method), the relevant Operator may disable your access to the relevant Items and/or retrieve the relevant Items from you. We shall not be responsible to compensate or indemnify you for any such loss of access or any other losses that you may suffer or incur in this regard.
                        If a dispute arises in relation to Items, including in relation to disputes regarding the price and/or quality of Items bought and sold via the Services, such dispute shall be resolved by you and the Operator only, without any involvement from us. You hereby release us (and our affiliates, Payment Provider, employees, representatives and agents) from claims, demands and damages (actual, direct, consequential or otherwise) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such dispute. However, we will have the right, in our sole discretion, to facilitate and assist in such disputes, but we will not in any way be a party or be deemed to be a party to such disputes.
                        You irrevocably authorise us to assist in the settlement of disputes arising from your use of the Services which may include instructing the Payment Provider to hold or transfer all or part of such disputed funds to a disputing party (subject to a reversion of the transfer depending on the final outcome of the dispute). In such event, you shall indemnify us for any liabilities arising out of our decision.
                    </span>
                    <br /><br />
                </p>
                <p>
                    9.3 <span className={style.wrapper_tabs}>
                        Subscriptions <br />
                        The Services may allow you to purchase access to content on a subscription basis.  These subscriptions may be offered on an automatic, continuous monthly basis ("<b>Monthly Subscription(s)</b>") or on a one-off, fixed term non-continuous basis ("<b>One-off Subscription(s)</b>").
                        Monthly Subscriptions will be automatically charged on a monthly 30-day basis, and you may be charged no more than 24 hours before the beginning of each monthly billing period. If we cannot charge your method of payment for any reason (such as insufficient funds or expiration of your payment method), we shall terminate your Monthly Subscription for the following month. We may, from time to time, in our sole discretion, offer promotions if you purchase a Monthly Subscription. The terms and conditions of any such promotions shall be determined by us in our sole discretion and will be provided to you at the time when you purchase a Monthly Subscription.
                        You can cancel your Monthly Subscription at any time before the beginning of the relevant monthly billing period, and the cancellation will apply to the following monthly billing period. For example, if you cancel your Monthly Subscription at any time in any given monthly billing period, the Monthly Subscription will be cancelled as of the following monthly billing period and you will not receive a refund for the current monthly billing period.
                        If you wish to cancel your Monthly Subscription, please visit our website or you can instruct your Payment Provider directly to cancel your subscription payment. You agree that we shall deem any instructions received from your Payment Provider to us as duly authorized by you and we shall have no liability to you in connection with any such instruction. You agree to irrevocably authorize us to instruct the relevant Operators to cancel your Monthly Subscription.
                        One-off Subscriptions will be charged once at the time when the subscription is made and you will not receive any refund for the One-off Subscription amount.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>OUR INTELLECTUAL PROPERTY RIGHTS</p> <br />
                <p>
                    10.1 <span className={style.wrapper_tabs}>
                        All intellectual property rights in or to the Platform, and any software we make available for your use of the Services (including any future updates, upgrades and new versions to all such software) (the "Software"), will continue to belong to us and our licensors. Except as expressly provided in these Terms, you have no right to use our intellectual property rights, including our trademarks or product names (for example, "<b>PayZone</b>"), logos, domain names or other distinctive brand features, without our prior written consent.
                    </span>
                    <br /><br />
                </p>
                <p>
                    10.2 <span className={style.wrapper_tabs}>
                        You may not copy, modify, create derivative works, reverse compile, reverse engineer or extract source codes from the Software, and you may not sell, distribute, redistribute or sublicense the Software, except in each case to the extent that we may not prohibit you from doing so under applicable laws or regulations or you have our prior written consent to do so. Where applicable laws or regulations entitle you to reverse compile or extract source codes from the Software, you will first contact us to request the information you need.
                    </span>
                    <br /><br />
                </p>
                <p>
                    10.3 <span className={style.wrapper_tabs}>
                        We may from time to time provide updates to the Software. Such updates may occur automatically or manually. Please note that the Software may not operate properly or at all if upgrades or new versions are not installed by you. We do not guarantee that we will provide any updates for the Software, or that such updates will continue to support your device or system. All updates to the Software are subject to these Terms, except as otherwise specified by us.
                    </span>
                    <br /><br />
                </p>
                <p>
                    10.4 <span className={style.wrapper_tabs}>
                        We may in our discretion provide technical support for the Platform (whether for free or for a fee). We provide technical support without any guarantee or warranty of any kind, and subject always to these Terms.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>OPEN SOURCE SOFTWARE</p> <br />
                <p>
                    11.1 <span className={style.wrapper_tabs}>
                        The Software may contain software that are subject to “open source” licences (the "<b>Open Source Software</b>").  Where we use such Open Source Software, please note that: <br /><br />

                        <p className={style.wrapper_tabs_inside}>. <span className={style.wrapper_tabs}>there may be provisions in the Open Source Software's licence that expressly override these Terms, in which case such provisions shall prevail to the extent of any conflict with these Terms; and</span></p> <br />
                        <p className={style.wrapper_tabs_inside}>. <span className={style.wrapper_tabs}>we will credit the relevant Open Source Software used in the Software within an Appendix to these terms and/or within the relevant Software.</span></p> <br />
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>YOUR DEVICE</p> <br />
                <p>
                    12.1 <span className={style.wrapper_tabs}>
                        You may need an adequate internet connection in order to use the Services. You may also be required to activate certain functionalities within the Platform in the manner described within the Platform. You may not be able to use certain functionalities within the Platform if you do not comply with such requirements.
                    </span>
                    <br /><br />
                </p>
                <p>
                    12.2 <span className={style.wrapper_tabs}>
                        Please note that we are not responsible for any third party charges you may incur (including any charges from your internet and telecommunication services providers) in relation to or arising from your use of the Software or the Services.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>PAID SERVICE</p> <br />
                <p>
                    13.1 <span className={style.wrapper_tabs}>
                        Certain functions in the Services may be paid services. If you use those paid services, you will be charged a fee.
                    </span>
                    <br /><br />
                </p>
                <p>
                    13.2 <span className={style.wrapper_tabs}>
                        We may make amendments and changes, in our absolute discretion, to the amount of fees we charge and the method of payment in relation to the paid services. We may also impose fees on any existing free-of-charge services. Prior to such changes, we will make an announcement or notification. If you disagree with such amendment or change you must immediately stop using the relevant service.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>COMPLIANCE WITH LAWS</p> <br />
                <p>
                    14.1 <span className={style.wrapper_tabs}>
                        You are solely responsible for understanding and complying with all laws and regulations applicable to your use of the Services. You must not use the Services in any way which breaches applicable laws.
                    </span>
                    <br /><br />
                </p>
                <p>
                    14.2 <span className={style.wrapper_tabs}>
                        If the country or region in which you are located prohibits or restricts all or any part of the Services, you must immediately cease using the Services.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>OUR LIABILITY</p> <br />
                <p>
                    15.1 <span className={style.wrapper_tabs}>
                        We will use reasonable care to ensure that the Services will be uninterrupted and that your instructions will be carried out promptly and be error-free. However, due to the nature of the Services and their reliance on the internet, payments systems and third parties such as Operators and Payment Providers, we are unable to provide any guarantees in this regard. In addition, you acknowledge and agree that your access to the Services may also be occasionally suspended, disrupted or restricted due to: (i) systems and network repairs and maintenance, or the introduction of new facilities or services; and/or (ii) bank and payments systems processing, clearing and settlement processing times.
                    </span>
                    <br /><br />
                </p>
                <p>
                    15.2 <span className={style.wrapper_tabs}>
                        To the extent permitted by applicable laws and regulations, our total aggregate liability of us and our affiliates, for all claims in connection with these terms and conditions or the Services, arising out of any circumstances, shall be limited to the greater of the following amounts: (i) the amount that you have paid to us for your use of the Services in the 6 months immediately preceding the date of the most recent claim; and (ii) USD100.
                    </span>
                    <br /><br />
                </p>
                <p>
                    15.3 <span className={style.wrapper_tabs}>
                        To the extent permitted by applicable laws and regulations, in no event will we or our affiliates be liable or responsible for: <br /><br />

                        <span className={style.wrapper_tabs_inside}>(a) <span className={style.wrapper_tabs}>any losses or damages that were caused by any natural disaster or other circumstance beyond our reasonable control; </span></span> <br />
                        <span className={style.wrapper_tabs_inside}>(b) <span className={style.wrapper_tabs}>any computer virus, trojan horse or other damage caused by malware or hackers;</span></span> <br />
                        <p className={style.wrapper_tabs_inside}>(c) <span className={style.wrapper_tabs}>any malfunction or failure of our or your software, system, hardware or connectivity; </span></p> <br />
                        <span className={style.wrapper_tabs_inside}>(d) <span className={style.wrapper_tabs}>losses that were not caused by any breach of these Terms by us; </span></span> <br />
                        <span className={style.wrapper_tabs_inside}>(e) <span className={style.wrapper_tabs}>risks and losses caused by your non-compliance with any applicable laws, or these Terms; </span></span> <br />
                        <span className={style.wrapper_tabs_inside}>(f) <span className={style.wrapper_tabs}>any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure); or</span></span> <br />
                        <p className={style.wrapper_tabs_inside}>(g) <span className={style.wrapper_tabs}>any indirect or consequential losses (regardless of whether or not such indirect or consequential losses are foreseeable to us) or any incidental,  .                                                     punitive, special and consequential damages, in each case, arising out of or in connection with these Terms, any other agreements which apply to the Services, the  Services themselves, your inability to use the Services or in connection with any Items purchased or obtained or messages received or transactions paid for through the Services, whether or not we have been advised of the possibility of such damage.</span></p> <br />
                    </span>
                    <br /><br />
                </p>
                <p>
                    15.4 <span className={style.wrapper_tabs}>
                        We will not be liable for any currency depreciation, foreign exchange loss, loss of interest and other risks in relation to the amount kept, collected or paid on your behalf or incurred as a result of using the Services. We are not required to pay to you any interest accrued thereon and you agree that we, or the Payment Provider, shall be entitled to retain any such interest.
                    </span>
                    <br /><br />
                </p>
                <p>
                    15.5 <span className={style.wrapper_tabs}>
                        To the fullest extent permitted by applicable law, we will not be liable to you for the interruption or disruption of the Services or any possible losses to you in any circumstance which is not reasonably foreseeable by us (which exemption includes liability that would otherwise arise for any direct loss).
                    </span>
                    <br /><br />
                </p>
                <p>
                    15.6 <span className={style.wrapper_tabs}>
                        To the fullest extent permitted by applicable law, we will be exempted from any liabilities in relation to the following risks which you may encounter: <br /><br />

                        <span className={style.wrapper_tabs_inside}>(a) <span className={style.wrapper_tabs}>information with threatening, defamatory or illegal content including from anonymous sources or someone using a fake or fictitious name;</span></span> <br />
                        <span className={style.wrapper_tabs_inside}>(b) <span className={style.wrapper_tabs}>you being misled or deceived by any person which results in psychological or physical harm and/or economic loss;</span></span> <br />
                        <p className={style.wrapper_tabs_inside}>(c) <span className={style.wrapper_tabs}>your computer system being destroyed, paralysed or unable to operate in normal condition;</span></p> <br />
                        <span className={style.wrapper_tabs_inside}>(d) <span className={style.wrapper_tabs}>credit or debit card fraud; or</span></span> <br />
                        <span className={style.wrapper_tabs_inside}>(e) <span className={style.wrapper_tabs}>identity theft.</span></span> <br />
                    </span>
                    <br /><br />
                </p>
                <p>
                    15.7 <span className={style.wrapper_tabs}>
                        Upon receipt of your payment instruction for any purchase of Items (including but not limited to Monthly Subscriptions and One-Off Subscriptions), you authorise us to allow the Payment Provider, or our or the Payment Provider’s bank or third party partners, service providers or agents, to charge or debit from your debit or credit card the amount that you requested be paid according to your payment instruction. In such event, you shall not submit a request to us for a refund, and we will have no liability to you, in connection with any actual or purported payment instruction, by reason of unsigned receipt, inconsistent signature, or the transaction not being in accordance with your intention or for any other reason. You also authorise us, the Payment Provider and our or the Payment Provider’s bank or third party partners, service providers or agents to initiate credits, debits or other charges to your debit or credit card to process subsequent refunds, chargebacks or other adjustments related to your payment transaction. In the event you change your payment method or any relevant details (including but not limited to your credit card number, its expiration date and/or your billing address), or if your payment account expires or is cancelled for any reason, you agree to notify us promptly of any such details.
                    </span>
                    <br /><br />
                </p>
                <p>
                    15.8 <span className={style.wrapper_tabs}>
                        You hereby release us (and our affiliates (excluding Operators, whose liabilities are addressed under the Operator's Terms), employees, representatives and agents) from claims, demands and damages (actual, direct, consequential or otherwise) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with any dispute in relation to any purchase or sale of Items through the Platform.
                    </span>
                    <br /><br />
                </p>
                <p>
                    15.9 <span className={style.wrapper_tabs}>
                        We do not guarantee the legality, authenticity or quality of Items listed for sale via the Services, nor do we guarantee your ability to complete a transaction with an Operator or make payment through a Payment Provider. We will not be liable to compensate or indemnify you for any loss suffered by you arising from the authenticity or quality of the Items bought by you via the Services.
                    </span>
                    <br /><br />
                </p>
                <p>
                    15.10 <span className={style.wrapper_tabs}>
                        Nothing in these terms and conditions limits or excludes any of the following liabilities, except to the extent that such liabilities may be waived, limited or excluded under applicable laws and regulations: <br /><br />

                        <span className={style.wrapper_tabs_inside}>(a) <span className={style.wrapper_tabs}>any liability for death or personal injury caused by either Party’s negligence;</span></span> <br />
                        <span className={style.wrapper_tabs_inside}>(b) <span className={style.wrapper_tabs}>any liability for fraud or fraudulent misrepresentation;</span></span> <br />
                        <p className={style.wrapper_tabs_inside}>(c) <span className={style.wrapper_tabs}>any liability for wilful misconduct; or</span></p> <br />
                        <p className={style.wrapper_tabs_inside}>(d) <span className={style.wrapper_tabs}>any other liability to the extent that such liability cannot be waived, limited or excluded under applicable laws and regulations.</span></p> <br />
                    </span>
                    <br /><br />
                </p>
                <p>
                    15.11 <span className={style.wrapper_tabs}>
                        Nothing in these Terms affect your legal statutory rights (to the extent applicable) to have Items provided to you by the relevant Operator within a reasonable time or to receive a refund from the relevant Operator if Items ordered cannot be supplied by the relevant Operator within a reasonable time.
                    </span>
                    <br /><br />
                </p>
                <p>
                    15.12 <span className={style.wrapper_tabs}>
                        To the extent permitted by applicable law, you agree that you (and your organisation, if you are using the Services on behalf of such organisation) will indemnify us, our partners, and our affiliates (excluding Operators) from and against any claim, suit, action, demand, damage, debt, loss, cost, expense (including litigation costs and attorneys’ fees) and liability arising from: (i) your use of the Services; or (ii) your breach of these terms and conditions.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>INTERRUPTION, SUSPENSION AND TERMINATION OF THE SERVICE</p> <br />
                <p>
                    16.1 <span className={style.wrapper_tabs}>
                        Without limiting the generality of Clause 15.1, we may suspend, interrupt or terminate or impose limitations on your use of the Services in accordance with our business or risk control requirements or those of our affiliates, including the Payment Provider.
                    </span>
                    <br /><br />
                </p>
                <p>
                    16.2 <span className={style.wrapper_tabs}>
                        We are entitled to interrupt, suspend or terminate the Services without notice in the following circumstances where:<br /><br />

                        <span className={style.wrapper_tabs_inside}>(a) <span className={style.wrapper_tabs}>you provide us with false or incorrect information;</span></span> <br />
                        <span className={style.wrapper_tabs_inside}>(b) <span className={style.wrapper_tabs}>you violate applicable laws or the provisions of these Terms;</span></span> <br />
                        <span className={style.wrapper_tabs_inside}>(c) <span className={style.wrapper_tabs}>the Services are required to be interrupted, suspended or terminated by the provisions of applicable laws or by competent authorities;</span></span> <br />
                        <p className={style.wrapper_tabs_inside}>(d) <span className={style.wrapper_tabs}>you infringe the lawful rights and interests of third parties;</span></p> <br />
                        <p className={style.wrapper_tabs_inside}>(e) <span className={style.wrapper_tabs}>the Services are required to be interrupted, suspended or terminated for security reasons; or</span></p> <br />
                        <p className={style.wrapper_tabs_inside}>(f) <span className={style.wrapper_tabs}>you fail to pay us any fee we have charged.</span></p> <br />
                    </span>
                    <br /><br />
                </p>
                <p>
                    16.3 <span className={style.wrapper_tabs}>
                        For the avoidance of doubt, the exercise by us of any of our suspension or termination rights under these Terms shall be without any liability to you and without prejudice to any other right or remedy available to us under these Terms or applicable laws.
                    </span>
                    <br /><br />
                </p>
                <p>
                    16.4 <span className={style.wrapper_tabs}>
                        The provisions of these Terms, which by their nature and content, are intended, expressly or impliedly, to continue to have effect after the termination or expiration of these Terms shall survive and continue to bind you and us.
                    </span>
                    <br /><br />
                </p>
                <p>
                    16.5 <span className={style.wrapper_tabs}>
                        Where we suspend or terminate the Services as a result of a breach by you, we may instruct the Payment Provider to instruct your bank to transfer money or charge or debit your credit card to settle your obligations. In such event, you agree not to hold us or the Payment Provider liable for such actions.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>AMENDMENTS TO THESE TERMS</p> <br />
                <p>
                    We may make changes to these Terms at any time. You will be subject to the terms and conditions of these Terms in force at the time when you use the Services. If you continue using the Services after any amendment to or change of these Terms, you shall be deemed to have read, understood and agreed to such amendment or change. If you disagree, you must immediately stop using the Services. <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>ELECTRONIC COMMUNICATIONS</p> <br />
                <p>
                    We will communicate with you by e-mail or by posting notifications on the Platform. For contractual purposes, you consent to receive communications from us electronically and you agree that all agreements, notifications, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing, unless applicable laws specifically require a different form of communication. <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>GOVERNING LAW AND DISPUTE RESOLUTION</p> <br />
                <p>
                    Except to the extent where the applicable laws and regulations of your jurisdiction mandate otherwise (for instance, you may have statutory rights in your jurisdiction in relation to bringing or defending claims in a local court (including small claims court (or similar court)), these Terms and any dispute or claim arising out of or in connection with it shall be governed by the law of the Hong Kong Special Administrative Region. Any dispute, controversy or claim (whether in contract, tort or otherwise) arising out of, relating to, or in connection with these Terms, including its existence, validity, interpretation, performance, breach or termination, shall be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre under the Hong Kong International Arbitration Centre Administered Arbitration Rules in force when the Notice of Arbitration is submitted in accordance with those Rules. The seat of the arbitration shall be Hong Kong. There shall be one arbitrator only. The arbitration proceedings shall be conducted in English. <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>MISCELLANEOUS</p> <br />
                <p>
                    20.1 <span className={style.wrapper_tabs}>
                        The headings of these Terms and of the clauses are inserted for convenience only and shall not be relied upon in interpreting these Terms. Any agreement amending, supplementing, novating or restating these Terms shall form an integral part of these Terms and have the same legal effect.
                    </span>
                    <br /><br />
                </p>
                <p>
                    20.2 <span className={style.wrapper_tabs}>
                        If any of the terms and conditions contained in these Terms is deemed to be invalid, void, or for any reason unenforceable, that term or condition will be deemed severable and will not affect the validity and enforceability of any remaining terms and conditions.
                    </span>
                    <br /><br />
                </p>
                <p>
                    20.3 <span className={style.wrapper_tabs}>
                        You shall not assign any of your rights or transfer any of your rights or obligations under these Terms without our prior written consent. We may assign our rights or transfer by way of novation our rights and obligations under these Terms without your prior consent and you hereby consent to any such transfer. You agree that in the event of any assignment or novation by us, including without limitation, in connection with an acquisition of us or our assets, we are authorised to transfer any or all of your data relating to the Services.
                    </span>
                    <br /><br />
                </p>
                <p>
                    20.4 <span className={style.wrapper_tabs}>
                        These Terms are a contract between you and us. No other person will have any rights to enforce any of these Terms.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>TERMS SPECIFIC TO THE EUROPEAN UNION, UNITED KINGDOM, SWITZERLAND AND NORWAY</p> <br />
                <p>
                    21.1 <span className={style.wrapper_tabs}>
                        Clause 5 Warranty is replaced with the following: <br /> <br />
                        Our Services are provided to you on an "as is", "where-is" basis based on existing technology, however, we warrant to you that we will provide the Services using reasonable skill and care. When deciding whether to use the Services, you should be aware that we cannot guarantee that Items or information or content relating to the Items included on or available through the Services will be as represented by Operators that Operators will perform as promised, or that Items will represent fair value, retain their value or otherwise meet your expectations as to their worth, exchange value or utility.
                    </span>
                    <br /><br />
                </p>
                <p>
                    21.2 <span className={style.wrapper_tabs}>
                        Clause 9.2 Delivery of Items is replaced with the following: <br /> <br />
                        The Operator shall be responsible for the delivery of Items transacted through the Services. If you request a refund for any Items, or if the Operator cannot charge your payment method for any reason (such as insufficient funds or expiration of your payment method), the relevant Operator may disable your access to the relevant Items and/or retrieve the relevant Items from you. <br />
                        If a dispute arises in relation to Items, including in relation to disputes regarding the price and/or quality of Items bought and sold via the Services, you must try to resolve the dispute between you and the Operator. We may facilitate and assist in such disputes.
                    </span>
                    <br /><br />
                </p>
                <p>
                    21.3 <span className={style.wrapper_tabs}>
                        New Clause 9.4 is inserted as follows: <br /> <br />
                        Your right to change your mind <br />
                        When you purchase Items or paid services (as described in Clause 13), you have 14 days after the day we confirm we accept your order to change your mind and cancel the order. However, you will lose this right to change your mind when you receive the Items or other services if you agree to and acknowledge this when ordering. To exercise your right to cancel, please contact us at help@midasbuy.com. You may choose to use the model cancellation form included in Schedule 1 to these Terms.
                    </span>
                    <br /><br />
                </p>
                <p>
                    21.4 <span className={style.wrapper_tabs}>
                        Clause 13.2 Paid Service is replaced with the following: <br /> <br />
                        We may make amendments and changes to the amount of fees we charge and the method of payment in relation to the paid services. We may also impose fees on any existing free-of-charge services. Prior to such changes, we will make an announcement or notification. If you disagree with such amendment or change you must stop using the relevant service before we start charging for the service and you can cancel your contract with us by contacting us at help@midasbuy.com.
                    </span>
                    <br /><br />
                </p>
                <p>
                    21.5 <span className={style.wrapper_tabs}>
                        Clause 15 Our Liability is replaced with the following: <br /> <br />
                        We may make amendments and changes to the amount of fees we charge and the method of payment in relation to the paid services. We may also impose fees on any existing free-of-charge services. Prior to such changes, we will make an announcement or notification. If you disagree with such amendment or change you must stop using the relevant service before we start charging for the service and you can cancel your contract with us by contacting us at help@midasbuy.com.
                        <p className={style.wrapper_tabs_inside}>(a) <span className={style.wrapper_tabs}>We will use reasonable care to ensure that the Services will be uninterrupted and that your instructions will be carried out promptly and be error-free. However, due to the nature of the Services and their reliance on the internet, payments systems and third parties such as Operators and Payment Providers, we are unable to provide any guarantees in this regard. In addition, you acknowledge and agree that your access to the Services may also be occasionally suspended, disrupted or restricted due to: (i) systems and network repairs and maintenance, or the introduction of new facilities or services; and/or (ii) bank and payments systems processing, clearing and settlement processing times.</span></p> <br />
                        <p className={style.wrapper_tabs_inside}>(b) <span className={style.wrapper_tabs}>If defective digital content that we have supplied damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</span></p> <br />
                        <p className={style.wrapper_tabs_inside}>(c) <span className={style.wrapper_tabs}>We only provide the Services for domestic and private use. If you use the Services for any commercial, business or re-sale purpose we will have no liability to you for any loss of profit, loss of business, business interruption or loss of business opportunity.</span></p> <br />
                        <p className={style.wrapper_tabs_inside}>(d) <span className={style.wrapper_tabs}>We are not responsible for delays outside our control. If our supply of the Services is delayed by an event outside our control then we will notify you via the Platform and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event, but if there is a risk of substantial delay you may contact us to end the contract and receive a refund for any Items or paid services which you have paid for but not received.</span></p> <br />
                        <p className={style.wrapper_tabs_inside}>(e) <span className={style.wrapper_tabs}>We do not voluntarily accept responsibility for any loss or damage: <br />
                            (i)     that was not caused by our breach of these Terms; or <br />
                            (ii) that was not, at the time you agreed to these Terms, a reasonably foreseeable consequence of us breaching these Terms (loss or damage is foreseeable if either it is obvious that it will happen or if, at the time these Terms were entered into, both we and you knew it might happen).
                        </span></p> <br />
                        <p className={style.wrapper_tabs_inside}>(f) <span className={style.wrapper_tabs}>As long as we have complied with these Terms including our obligation to exercise reasonable care in providing the Services, we will not be responsible for the following and you should take reasonable steps to protect yourself against these risks: <br />
                            (i)   information with threatening, defamatory or illegal content including from anonymous sources or someone using a fake or fictitious name; <br />
                            (ii) you being misled or deceived by any person which results in psychological or physical harm and/or economic loss; <br />
                            (iii) your computer system being destroyed, paralysed or unable to operate in normal condition; <br />
                            (iv)   credit or debit card fraud; or <br />
                            (v)    identity theft.</span>
                        </p> <br />
                        <p className={style.wrapper_tabs_inside}>(g) <span className={style.wrapper_tabs}>Upon receipt of your payment instruction for any purchase of Items (including but not limited to Monthly Subscriptions and One-Off Subscriptions), you authorise us to allow the Payment Provider, or our or the Payment Provider’s bank or third party partners, service providers or agents, to charge or debit from your debit or credit card the amount that you requested be paid according to your payment instruction. In such event, you shall not submit a request to us for a refund, and we will have no liability to you, in connection with any actual or purported payment instruction, by reason of unsigned receipt, inconsistent signature, or the transaction not being in accordance with your intention or for any other reason. You also authorise us, the Payment Provider and our or the Payment Provider’s bank or third party partners, service providers or agents to initiate credits, debits or other charges to your debit or credit card to process subsequent refunds, chargebacks or other adjustments related to your payment transaction. In the event you change your payment method or any relevant details (including but not limited to your credit card number, its expiration date and/or your billing address), or if your payment account expires or is cancelled for any reason, you agree to notify us promptly of any such details.</span></p> <br />
                        <p className={style.wrapper_tabs_inside}>(h) <span className={style.wrapper_tabs}>We do not guarantee the legality, authenticity or quality of Items listed for sale via the Services. We will not be liable to compensate you for any loss suffered by you arising from the authenticity or quality of the Items bought by you via the Services.</span></p> <br />
                        <p className={style.wrapper_tabs_inside}>(i) <span className={style.wrapper_tabs}>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</span></p> <br />
                        <p className={style.wrapper_tabs_inside}>(j) <span className={style.wrapper_tabs}>Nothing in these Terms affect your legal statutory rights (to the extent applicable) for example to have Items provided to you by the relevant Operator within a reasonable time or to receive a refund from the relevant Operator if Items ordered cannot be supplied by the relevant Operator within a reasonable time.</span></p> <br />
                    </span>
                    <br /><br />
                </p>
                <p>
                    21.6 <span className={style.wrapper_tabs}>
                        New Clause 16.6 is inserted as follows: <br /> <br />
                        We may choose to withdraw or suspend the Services or Platform at any time. We will give you reasonable notice in advance of doing so and will refund you for any Services or Items already paid for which will not be provided.
                    </span>
                    <br /><br />
                </p>
                <p>
                    21.7 <span className={style.wrapper_tabs}>
                        New Clause 16.7 is inserted as follows:<br /> <br />
                        You can stop using the Services and cancel your contract with us at any time by contacting us at help@midasbuy.com.
                    </span>
                    <br /><br />
                </p>
                <p>
                    21.8 <span className={style.wrapper_tabs}>
                        Clause 17 Amendments to these Terms is replaced with the following:<br /> <br />
                        We may make changes to these Terms at any time due to changes in laws or regulatory requirements or to implement minor technical adjustments or improvements. We will notify you of any such changes including via the Platform. You will be subject to the terms and conditions of these Terms in force at the time when you use the Services. If you continue using the Services after any amendment to or change of these Terms, you shall be deemed to have read, understood and agreed to such amendment or change. If you disagree with any such amendment or change, you must stop using the Services prior to the amendment or change taking effect and you can cancel your contract with us by contacting us at help@midasbuy.com. We will refund you for any Services or Items which you have already paid for but not yet received.
                    </span>
                    <br /><br />
                </p>
                <p>
                    21.9 <span className={style.wrapper_tabs}>
                        Clause 19 Governing Law and Dispute Resolution is replaced with the following:<br /> <br />
                        These Terms are governed by the law of the country in which you live and you can bring legal proceedings in your local courts. In addition, if you are in the European Economic Area please note that disputes may be submitted for online resolution to the European Commission Online Dispute Resolution platform.
                    </span>
                    <br /><br />
                </p>
                <p>
                    21.8 <span className={style.wrapper_tabs}>
                        New Clause 20.5 is inserted as follows:<br /> <br />
                        Even if we delay in enforcing these Terms, we can still enforce them later. If we do not insist immediately that you do anything you are required to do under these Terms, or if we delay in taking steps against you in respect of your breaching these Terms, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.
                    </span>
                    <br /><br />
                </p>
                <p className={style.wrapper_Uppercase}>Schedule 1 - Model Cancellation Form</p> <br />
                <p>
                    (Complete and return this form only if you wish to withdraw from the contract) <br />
                    To High Morale Developments Limited, 29/F, Three Pacific Place, No.1 Queen’s Road East, Wan Chai, Hong Kong, help@midasbuy.com: <br />
                    I/We [*] hereby give notice that I/We [*] cancel my/our [*] contract of sale of the following goods [*]/for the provision of the following service [*], <br />
                    Ordered on [*]/received on [*], <br />
                    Name of consumer(s), <br />
                    Address of consumer(s), <br />
                    Signature of consumer(s) (only if this form is notified on paper), <br />
                    Date <br />
                    [*] Delete as appropriate
                </p>
            </Container>
        </div>
    )
}
