import axios from "axios";
import { QueryClient } from "react-query";

let language = localStorage.getItem('Lang_Pay')
export const token =
  typeof window !== "undefined" && localStorage.getItem("JWT_Pay");

export const requestPayzone = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BASE_URL_PAYZONE,
  headers: {
    ...(token ? { Authorization: `Bearer ${token}` } : undefined),
    'lang': language || 'en',
  },
});

const errorHandler = (error) => {
  return Promise.reject(error.response);
};

requestPayzone.interceptors.response.use(
  (response) => response.data,
  errorHandler
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
